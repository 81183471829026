.presMain {
    margin: 2rem 0;
}

.heading h3 {
    font-size: 24px;
    margin: 1rem 0;
}

.patientDetails h4 {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;
}

.patientDetails span, .patientDetails address {
    color: #1D2225;
    font-weight: 400;
    font-size: 16px;
}
.form{
    margin-top: 2rem;
}