@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif !important;
}

:root {
    --Akos-Blue: #25406B;
    --Akos-Black: #1A1A1A;
    --Akos-white: #FFF;
    --Akos-Light-Grey: #C9C9C9;
}

body {
    background: var(--Akos-white, #FFF);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--Akos-Black, #1A1A1A);
    font-style: normal;
    line-height: normal;
    font-weight: 700;
}

p {
    color: var(--Akos-Black, #1A1A1A);
    font-style: normal;
    line-height: normal;
    font-weight: 400;
}

.customContainer {
    max-width: 90%;
    margin: auto;
}

.mainLayout {
    min-height: calc(100vh - 134.8px);
}

.customFormLabel {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
    margin-bottom: 8px !important;
    text-transform: capitalize;
}

.custumTextField {
    border-radius: 5px !important;
    border: 1px solid #C9C9C9 !important;
    background: var(--Akos-white) !important;
    padding: 8px 16px !important;
    outline: none !important;
}

.custumTextField .MuiOutlinedInput-notchedOutline {
    display: none !important;
}

.custumTextField .MuiOutlinedInput-root,
.custumTextField .MuiOutlinedInput-root input {
    width: 100%;
    padding: 0 !important;
}

.custumTextField .MuiSelect-select {
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    min-height: unset !important;
}

.errorMsg {
    color: #C51414;
    font-size: 16px;
    font-weight: 700;
}
.errorMsgValid {
    color: #C51414;
    margin-top: 5px;
}

.fillButton,
.fillButton:focus,
.fillButton:active,
.outLineBtn,
.outLineBtn:focus,
.outLineBtn:active {
    outline: none;
    box-shadow: none;
    border-radius: 16px;
    background: var(--Akos-Blue, #25406B);
    display: flex;
    padding: 10px 26px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Akos-white, #FFF);
    font-size: 16px;
    font-weight: 700;
    flex-wrap: nowrap;
    white-space: nowrap;
    border: 1.5px solid transparent;
}

.outLineBtn,
.outLineBtn:focus,
.outLineBtn:active {
    background: var(--Akos-white, #FFF);
    color: var(--Akos-Blue, #25406B);
    border-radius: 9.718px;
    border: 1.5px solid var(--Akos-Blue, #25406B);
}

/* table start */

.TableContainer {
    box-shadow: none !important;
    background: none !important;
}

.custumTableRow th,
.custumTableRow td,
.custumTableRow th p,
.custumTableRow th span {
    border-bottom: 1px solid #EEE;
    color: var(--Akos-Black, #1A1A1A);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 1;
    text-align: center !important;
}

.custumTableRow th p,
.custumTableRow th span {
    border: none;
}

.custumTableHead {
    background: var(--Akos-Blue, #25406B);
}

.custumTableHead th {
    color: var(--Akos-white, #FFF);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    border-bottom: 1px solid #EEE;
}


/* table end */

/* modal style */
.ModalBoxStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background: var(--Akos-white, #FFF);
    outline: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 80vh;
    margin: auto;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
}

.modalHeader h3 {
    max-width: 85%;
    color: #000;
    font-size: 16px;
    font-weight: 700;
}

.modalHeader svg {
    cursor: pointer;
}

.modalBody {
    max-height: calc(100vh - 200px);
    min-height: 200px;
    overflow: auto;
    padding: 10px 20px;
}

.modalBody::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #EEE;
}

.modalBody::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 4px;
}

.modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
    padding: 20px;
}

/* modal style */

::placeholder,
textarea::placeholder {
    color: var(--Akos-Black, #1A1A1A) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.3;
}

.custumSignature {
    width: 100%;
    border: 1px solid #C9C9C9;
    height: 200px;
    padding: 1rem;
    border-radius: 4px;
}

.clearSignature {
    border-bottom: 1px solid #C9C9C9;
    margin-top: 1rem;
    font-size: 16px;
    color: var(--Akos-Black);
    font-weight: 600;
}

.fillButton:disabled {
    background: #B2C7E6 !important;
}

.flexRowImp {
    flex-direction: row !important;
}
@media screen and (max-width: 1200px) {
    .ModalBoxStyle {
        max-width: 80%;
        max-height: 80vh;
    }
}