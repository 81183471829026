.header {
    border: 1px solid #EEE;
    background: var(--Akos-white, #FFF);
    padding: 5px 0;
}

.headerMain {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logout button{
    padding: 6px 20px !important;
}

.logo img {
    height: 80px;
}

.footer {
    background: var(--Akos-Blue);
}

.footerItems {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.footerItems li {
    font-size: 17px;
    color: #fff;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .footerItems {
        flex-direction: column-reverse;
        gap: 10px;
    }
    .logo img{
        height: 50px;
    }
}