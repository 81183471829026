.loginHead{
    margin-bottom: 1rem;
}
.loginHead h3 {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 30px;
    font-weight: 700;
}

.loginHead p {
    color: var(--Akos-Black, #1A1A1A);
    font-size: 20px;
    font-weight: 400;
}


.loginMain {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 134.8px);
}

.loginBox {
    width: 400px;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid rgba(22, 25, 28, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 9.3px 0px rgba(0, 0, 0, 0.08);
}